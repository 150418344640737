import React from 'react'
import DefaultView from '../../views/DefaultView/DefaultView'
import SectionAbout from './components/SectionAbout/SectionAbout'
import SectionBanner from './components/SectionBanner/SectionBanner'
import SectionBuildings from './components/SectionBuildings/SectionBuildings'
import SectionCallback from './components/SectionCallback/SectionCallback'
import SectionAdvantages from './components/SectionAdvantages/SectionAdvantages'
import SectionArticles from './components/SectionArticles/SectionArticles'

const MainPage: React.FC = (): React.ReactElement => {
    return (
        <DefaultView isMain
                     pageTitle='Недвижимость Сочи, дома, квартиры, земельные участки Сочи'
                     pageDescription='На сайте СОЧИДОМИНВЕСТ, представлена недвижимость Сочи от собственников, без посредников. Квартиры, дома, земельные участки Сочи имеют все необходимые документы и готовы к продаже, инвестированию.'
        >
            <SectionBanner/>

            <SectionBuildings/>

            <SectionCallback/>

            <SectionAbout/>

            <SectionAdvantages/>

            <SectionArticles/>
        </DefaultView>
    )
}

MainPage.displayName = 'MainPage'

export default React.memo(MainPage)